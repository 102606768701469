import React, { useState, useRef } from "react"
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
} from "react-bootstrap"
import Metatags from "../../components/metatags"
import {
  handleVerifyEmail,
  sigin,
  handleSendPasswordRecovery,
} from "../../services/auth"
import { navigate } from "gatsby"
import { Icon } from "react-icons-kit"
import { envelope } from "react-icons-kit/fa/envelope"
import { key } from "react-icons-kit/icomoon/key"
import { eye } from "react-icons-kit/fa/eye"
import { eyeSlash } from "react-icons-kit/fa/eyeSlash"
import ErrorModal from "../../components/error-modal"
import "../../css/multilogica-theme.scss"

function Signup() {
  const [formEmail, setFormEmail] = useState("")
  const [formPass, setFormPass] = useState("")
  const [isRevealPassword, setIsRevealPassword] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [errorField, setErrorField] = useState([])
  const [buttonContinueEnabled, setButtonContinueEnabled] = useState(true)
  const [spinnerEntryActive, setSpinnerEntryActive] = useState(false)
  const [passwordWarning, setPasswordWarning] = useState(" ")
  const [buttonEntryEnabled, setButtonEntryEnabled] = useState(true)
  const [spinnerPasswordActive, setSpinnerPasswordActive] = useState(false)
  const [spinnerPasswordRecovery, setSpinnerPasswordRecovery] = useState(false)
  const [modalEmailSent, setModalEmailSent] = useState(false)
  const [showErrorConnModal, setShowErrorConnModal] = useState(false)

  const submitForm = async () => {
    if (verifyAllRules(formPass)) {
      setButtonEntryEnabled(false)
      setSpinnerPasswordActive(true)
      await sigin({
        email: formEmail,
        password: formPass,
      }).then(res => {
        if (res.status === 201 || (res.response && res.response.status === 401) || typeof res.status !== 'undefined') {
          if (res.status === 201) {
            localStorage.setItem("jwt", res.data.accessToken)

            setButtonEntryEnabled(false)
            setPasswordWarning(" ")
            setSpinnerPasswordActive(true)

            if (localStorage.getItem("redirect")) {
              navigate(localStorage.getItem("redirect"))
            } else {
              navigate("/user/account")
            }
          } else {
            setButtonEntryEnabled(true)
            setPasswordWarning("senha incorreta")
            setSpinnerPasswordActive(false)
          }
        } else {
          setSpinnerPasswordActive(false)
          setShowErrorConnModal(true)
        }
      })
    } else {
      setErrorField(["formPass"])
    }
  }

  const verifyEmailDB = async () => {
    if (verifyEmailString(formEmail)) {
      setButtonContinueEnabled(false)
      setSpinnerEntryActive(true)
      await handleVerifyEmail(formEmail).then(res => {
        if (typeof res.isAxiosError === "undefined" || !res.isAxiosError) {
          if (res.data.isEmailRegistred) {
            setValidEmail(true)
            setButtonContinueEnabled(false)
            setSpinnerEntryActive(true)
          } else {
            setErrorField(["formEmail"])
            setButtonContinueEnabled(true)
            setSpinnerEntryActive(false)
          }
        } else {
          setSpinnerEntryActive(false)
          setShowErrorConnModal(true)
        }
      })
    } else {
      setErrorField(["formEmail"])
    }
  }

  const sendPasswordRecovery = async () => {
    setSpinnerPasswordRecovery(true)
    await handleSendPasswordRecovery({ email: formEmail }).then(res => {
      if (!res.isAxiosError) {
        setModalEmailSent(true)
      } else {
        setShowErrorConnModal(true)
      }
    })
  }

  const verifyEmailString = email => {
    const re = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    )
    if (re.test(email)) {
      return true
    } else {
      return false
    }
  }

  const passWordRules = {
    data: [
      {
        regex: "^.{8,}",
        errorMessage: "a senha deve ter pelo menos 8 caracteres",
      },
    ],
  }

  const verifyAllRules = actualPassword => {
    let passOk = true
    passWordRules.data.map(rule => {
      if (!new RegExp(rule.regex).test(actualPassword)) {
        passOk = false
      }
      return null
    })
    return passOk
  }

  const togglePassword = () => {
    setIsRevealPassword(!isRevealPassword)
  }

  const formateField = evt => {
    evt.preventDefault()
    if (evt.target.id === "formEmail") {
      setFormEmail(evt.target.value)
      if (verifyEmailString(evt.target.value)) {
        removeError(evt.target.id)
      }
    } else {
      setFormPass(evt.target.value)
      if (verifyAllRules(evt.target.value)) {
        removeError(evt.target.id)
      }
    }
  }

  const removeError = errName => {
    if (errorField.includes(errName)) {
      let errorFieldAux = errorField
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorField(errorFieldAux)
    }
  }

  const removeModal = () => {
    setModalEmailSent(false)
    navigate("/")
  }

  const removeErrorConnModal = () => {
    setShowErrorConnModal(!showErrorConnModal)
  }

  const ref = useRef()

  return (
    <>
      <ErrorModal
        modalErro={showErrorConnModal}
        removeErrorConnModal={removeErrorConnModal}
      />
      <Metatags
        title="Multilogica | Blog"
        description="O Blog da Multilógica-shop"
        url="https://blog.multilogica-shop.com"
        image="https://blog.multilogica-shop.com/Multilogica-shop.png"
        keywords="eletrônica, arduino, micro:bit, raspberry pi, sparkfun, pololu"
      />
      <div className="height-40" />
      <Modal show={modalEmailSent}>
        <Modal.Body>
          <p>Enviamos um email com um link para você recuperar sua senha.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Container style={{ display: validEmail ? "none" : "block" }}>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <Container>
              <Row id="contato">
                <Col className="mb-lg-auto" lg="12">
                  <div className="text-center">
                    <h3>Login</h3>
                  </div>
                  <div className="height-40" />
                  <Form>
                    <Form.Group controlId="formEmail">
                      <Form.Label>
                        <Icon size={"16px"} icon={envelope} />
                        &nbsp;email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        value={formEmail}
                        onChange={evt => formateField(evt)}
                        className={
                          errorField.includes("formEmail") ? "errorBorder" : ""
                        }
                      />
                    </Form.Group>
                    {errorField.includes("formEmail") ? (
                      <p>Esse email não está registrado.</p>
                    ) : (
                      <p>&nbsp;</p>
                    )}
                    <Button
                      variant="primary"
                      onClick={verifyEmailDB}
                      disabled={!buttonContinueEnabled}
                      style={{ float: "left" }}
                    >
                      Continuar
                    </Button>
                    <div
                      style={{
                        display: spinnerEntryActive ? "block" : "none",
                        float: "left",
                        margin: "4px 0 0 6px",
                      }}
                    >
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  </Form>
                </Col>
              </Row>
              <div className="height-40" />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container style={{ display: validEmail ? "block" : "none" }}>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <Container>
              <Row id="contato">
                <Col className="mb-lg-auto" lg="12">
                  <Container>
                    <Row>
                      <Col>
                        <div className="text-center">
                          <h3>Login</h3>
                        </div>
                        <div className="height-40" />
                        <Form>
                          <Form.Group controlId="formPass">
                            <Form.Label>
                              <Icon size={"16px"} icon={key} />
                              &nbsp;Senha
                            </Form.Label>
                            <Form.Control
                              type={isRevealPassword ? "text" : "password"}
                              autoComplete="new-password"
                              onChange={evt => formateField(evt)}
                              className={
                                errorField.includes("formPass")
                                  ? "errorBorder"
                                  : ""
                              }
                            />
                            <span
                              onClick={togglePassword}
                              ref={ref}
                              onKeyDown={() => null}
                              role="button"
                              tabIndex={0}
                              style={{ outline: 0 }}
                            >
                              <div
                                style={{ float: "right", marginTop: "-30px" }}
                              >
                                {isRevealPassword ? (
                                  <Icon
                                    size={"20px"}
                                    icon={eye}
                                    style={{ marginRight: "6px" }}
                                  />
                                ) : (
                                  <Icon
                                    size={"20px"}
                                    icon={eyeSlash}
                                    style={{ marginRight: "6px" }}
                                  />
                                )}
                              </div>
                            </span>
                            <Form.Text className="text-muted email-warning">
                              {passwordWarning}
                            </Form.Text>
                          </Form.Group>
                        </Form>
                        <p>&nbsp;</p>
                        <Button
                          variant="primary"
                          onClick={submitForm}
                          disabled={!buttonEntryEnabled}
                          style={{ float: "left" }}
                        >
                          Entrar
                        </Button>
                        <div
                          style={{
                            display: spinnerPasswordActive ? "block" : "none",
                            float: "left",
                            margin: "4px 0 0 6px",
                          }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          className="simple-button"
                          onClick={sendPasswordRecovery}
                          style={{
                            float: "left",
                          }}
                        >
                          esqueci minha senha
                        </Button>
                        <div
                          style={{
                            display: spinnerPasswordRecovery ? "block" : "none",
                            float: "left",
                            margin: "4px 6px 0 0",
                          }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <div className="height-20" />

              <div className="height-40" />
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Signup
